<template>
  <div>
    <zg-hero
      :id="contentId"
      product="electricity"
      page-type="home-page"
      :title="title"
      :text="text"
      :tabs="tabs"
      :background="background"
      :breadcrumbs="breadcrumbs"
    >
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />
    </zg-hero>
  </div>
</template>

<script>
  import { ZgHero } from '@zc/components/ZgHero'

  export default {
    name: 'Hero',
    components: {
      ZgHero
    },
    props: {
      contentId: {
        type: String,
        default: null
      },
      breadcrumbs: {
        type: Array,
        default: null
      },
      background: {
        type: Object,
        default: null
      },
      tabs: {
        type: Array,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      }
    }
  }
</script>
